import React from "react";
import {useInterval} from '../tools/useInterval';
import json from "data.json"
// react-bootstrap components
import {
  Card,
  Table,
  Container,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { FaMinusCircle, FaPlusCircle, FaShare, FaShareAlt, FaThumbsUp } from 'react-icons/fa';
import bgbb from "assets/img/bgbb.png";
import bgld from "assets/img/bgld.png"
import { useHistory, useLocation } from 'react-router-dom'
import { jwtDecode } from "jwt-decode";


var teams = Object.keys(json).map((key) => [key, json[key]]);

function LiveList() {

  const [games, setGames] = React.useState([]);
  const [live, setLive] = React.useState({})
  const [favorites, setFavorites] = React.useState({})
  const [lastUpdated, setLastUpdated] = React.useState("")
  const [picksToken, setPicksToken] = React.useState("")
  const [picks, setPicks] = React.useState("")
  const [yesterdaysResults, setYesterdaysResults] = React.useState("")
  const [clock, setClock] = React.useState(new Date())
  const [earliestGame, setEarliestGame] = React.useState()
  const history = useHistory()
  const preseason = true

  const queryParameters = new URLSearchParams(window.location.search)
  const devDomain = "http://localhost:3001"; 
  const prodDomain = "https://api.threes.day";
  const devWebDomain = "http://localhost:3000";
  const prodWebDomain = "https://threes.day";
  const env = queryParameters.get("env")

  const domain = (!env || env == 'prod')?prodDomain:devDomain;
  const webDomain = (!env || env == 'prod')?prodWebDomain:devWebDomain;

  const apnToken = queryParameters.get("apnToken")
  const adminToken = "85c7c6bff63ae9947f324b1616a09786911caa8cad2e1049186a1d39ced57247";
  const admin = (apnToken==adminToken)?true:false;
  
  const picksGoLiveHour = 12;
  const pickURL = webDomain + "/admin/live?favorites="

  function getGames() {
    fetch(domain+'/live')
      .then(results => results.json())
      .then(data => {
        if(!data.scoreboard) { return }
        const {games} = data.scoreboard;
        const todaysGames = []
        games.forEach((game)=>{
          if(game && ( (game.gameStatusText != "Final" || (new Date(game.gameEt).getDate()) == (new Date().getDate())) || (game.gameStatusText == "Final" && (new Date(game.gameEt).getDate()) != (new Date().getDate())) )  ){
            //its today

            const homeTeam = game.homeTeam.teamId;
            const awayTeam = game.awayTeam.teamId;
            game.awayTeam.starters = {}
            game.homeTeam.starters = {}
            
            if(!game.homeTeam.players) { // no game status yet, just default
              game.homeTeam.players = json[homeTeam].players;
              game.awayTeam.players = json[awayTeam].players;
            } else {
              Object.values(game.homeTeam.players).map((player, i)=>{
                if(json[homeTeam].players[player.personId]){
                  player.threes = json[homeTeam].players[player.personId].threes
                  player.totalThreeAttempts = json[homeTeam].players[player.personId].totalThreeAttempts
                  player.totalThreeMade = json[homeTeam].players[player.personId].totalThreeMade
                  player.starts = json[homeTeam].players[player.personId].starts
                }
                if(!player.threes) { player.threes = {"attempts":{}, "made":{}, "firstTeamThree":{},"firstThreeTeamAttempt":{},"firstThree":{},"firstThreeAttempt":{}} }
                if(player.position) {
                  //starter
                  game.homeTeam.starters[player.personId] = player
                  //delete player
                }
              })
              Object.values(game.awayTeam.players).map((player, i)=>{
                if(json[awayTeam].players[player.personId]){
                  player.threes = json[awayTeam].players[player.personId].threes
                  player.totalThreeAttempts = json[awayTeam].players[player.personId].totalThreeAttempts
                  player.totalThreeMade = json[awayTeam].players[player.personId].totalThreeMade
                  player.starts = json[awayTeam].players[player.personId].starts
                }
                if(!player.threes) { player.threes = {"attempts":{}, "made":{}, "firstTeamThree":{},"firstThreeTeamAttempt":{},"firstThree":{},"firstThreeAttempt":{}} }
                if(player.position) {
                  //starter
                  game.awayTeam.starters[player.personId] = player
                  //delete player
                }
              })
            }

            todaysGames.push(game)
          }
        })
        console.log(todaysGames);
        setGames(todaysGames);
      });
  }

  function getLive() {
    fetch(domain+'/live/pbp')
    .then(results => results.json())
    .then(data => {
      const {games} = data;
      console.log(games)
      setLive(games)
      setLastUpdated(formaSecondsAMPM(new Date()))
    })
  }

  function getJWT() {
    fetch(domain+'/pick/today/jwt')
    .then(results => results.json())
    .then(data => {
      const {access_token} = data;
      const {favorites} = jwtDecode(access_token);
      console.log(favorites)
      var picks = {}
      Object.values(favorites).forEach((game)=>{
        Object.values(game.teams).forEach((team)=>{
          Object.values(team.players).forEach((player)=>{
            picks[player.playerId] = true
          })
        })
      })
      setPicks(picks)
      setPicksToken(access_token)
    })
  }

  function getYesterdaysRecap() {
    //yesterdaysResults
    fetch(domain+'/pick/yesterday/record')
    .then(results => results.json())
    .then(data => {
      const {record} = data;
      setYesterdaysResults(record.w + "/" + record.t)
    })
  }

  async function getFavorites() {
    //check to see if one was sent in the url
    const queryParameters = new URLSearchParams(window.location.search)
    const favoritesToSave = queryParameters.get("favorites")
    if(favoritesToSave) {
      await localStorage.setItem('favorites', decodeURIComponent(favoritesToSave))
      queryParameters.delete('favorites')
      history.replace({
        search: queryParameters.toString(),
      })

    } 
    const favorites = await localStorage.getItem('favorites') 
    if (!favorites) { 
      setFavorites({})
      return; 
    }
    fetch(domain+'/favorites', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + favorites,
      }})
    .then(results => results.json())
    .then(data => {
      const {favorites} = data;
      if(favorites) {
        setFavorites(favorites)
      } else {
        localStorage.removeItem("favorites")
      }
    }).catch(()=>{
      localStorage.removeItem("favorites")
    })
  }

  async function addFavorite(favorite) {
    console.log(favorite)
    const favorites = await localStorage.getItem('favorites') 
    fetch(domain+'/favorites', {
      method: (favorites)?'PATCH':'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + favorites,
      },
      body: JSON.stringify(favorite)}) 
    .then(results => results.json())
    .then(data => {
      const {access_token} = data;
      console.log(access_token)
      if(access_token) {
        localStorage.setItem("favorites", access_token)
        getFavorites()
      }
    }).catch(()=>{
      localStorage.removeItem("favorites")
    })
    console.log("is admin: "+admin)
    if(admin) {
      fetch(domain+'/pick', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({"gameId": favorite.gameId, "teamId":favorite.teamId, "playerId":favorite.playerId, apnToken: apnToken})}) 
      .then(results => results.json())
      .then(data => {
        console.log(data)
      })
    }
  }

  React.useEffect(() => {
    getGames()
    getLive()
    getFavorites()
    getJWT()
    getYesterdaysRecap()
  }, []); 

  useInterval(() => {
    if(clock.getHours() >= 12) {
      getLive()
    }
    if(clock.getHours() >= picksGoLiveHour){
      getJWT()
    }
  }, 1000*10);

  useInterval(()=>{
    var date =  new Date(new Date().toLocaleString('en-US', { timeZone: 'America/New_York' }))
    if(date.getHours() == 12 && date.getMinutes() == 0 && date.getSeconds() == 5){
      //reload for new data
        getGames()
    }
    setClock(date)
  }, 1000)

  function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  function formaSecondsAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    seconds = seconds < 10 ? '0'+seconds : seconds;
    var strTime = hours + ':' + minutes +':' + seconds + ' ' + ampm;
    return strTime;
  }

  async function copyURL(){
    const favorites = await localStorage.getItem('favorites') 

    navigator.clipboard.writeText(webDomain + "/admin/live?favorites="+encodeURIComponent(favorites).replace(/\./g, '%2E'));
    alert("Favorites Copied")
  }

  function header() {
    return (
      <span>
        {(clock.getHours()<12)?"Yesterdays":"Todays"} NBA Games<br />
        {(clock.getHours()>=12)?
        <small style={{fontSize:"18px"}}>Live Stats Last Updated: {lastUpdated}</small>:
        <small>Todays games update in {11-clock.getHours()}:{((59-clock.getMinutes())<10)?"0":""}{59 -clock.getMinutes()}:{((59-clock.getSeconds())<10)?"0":""}{59 -clock.getSeconds()}</small>
        }
      </span>
    );
  }

  return (
    <div style={{backgroundImage: `url(${bgld})`, padding: "50px 20px", marginTop:"-20px", minHeight:"100vh + 20px"  }}>
      
      <p class="title-header" style={{marginTop: "-20px", fontSize: "30px",align:"center", textAlign:"center"}}>
        {(!preseason)?header():"Preseason starts 10/4"}
      </p>
      <header />
      {(games && games.length >0 && clock.getHours()>=12 && clock.getHours()<picksGoLiveHour)?
        <p class="title-header" style={{ fontSize: "20px",align:"center", textAlign:"center"}}>
          AI Models currently running. Once complete, players will be highlighted in each game there are favorable predictions.
        </p>
      :(games && games.length >0 && clock.getHours()>=picksGoLiveHour && clock.getHours()<=24)?
      <p class="title-header">
        AI Models run complete for <u>first team three point made</u>. View highlighed players below or tap to replace your favorites with the AI favorites. <a href={pickURL + picksToken}>Replace favorites with AI favorites</a>
      </p>:<></>}
      {(clock.getHours()>=0 && clock.getHours()<12)?
      <p class="title-header">
        Yesterdays Recap: AI went {(yesterdaysResults)}. Todays processing will start at 1PM ET.
      </p>:<></>}
      {(favorites && Object.values(favorites).length >0)?
        <Container fluid style={{padding: "0"}}>
        <div class="title-header">
          Favorites
          <Button style={{float:"right", marginTop:"-5px"}} onClick={copyURL}>Share&nbsp;<FaShareAlt /></Button>
        </div>
        <Row>
            {(favorites && Object.values(favorites).length >0)?games.map((game, id) => (
              ((favorites[game.gameId])?
              <Col md="3" xs="6">

              <Card className="" style={{fontSize:"8px !important"}}>
                <Card.Header>
                  <Card.Title as="h6" >
                    {game.awayTeam.teamTricode}
                    {(!live[game.gameId]?.awayTeamScore  && game.awayTeam.score)?"("+game.awayTeam.score+")":""}
                    {(live[game.gameId]?.awayTeamScore)?"("+live[game.gameId]?.awayTeamScore+")":""}
                    &nbsp;@&nbsp;
                    {game.homeTeam.teamTricode}
                    {(!live[game.gameId]?.homeTeamScore  && game.homeTeam.score)?"("+game.homeTeam.score+")":""}
                    {(live[game.gameId]?.homeTeamScore)?"("+live[game.gameId]?.homeTeamScore+")":""}
                    {(!live[game.gameId])?<>
                      <small>{new Date(game.gameEt).getMonth()+1}/{new Date(game.gameEt).getDate()} {formatAMPM(new Date(game.gameTimeUTC))}</small>
                    </>:""}
                    {(live[game.gameId])?<>
                      <br />
                      {live[game.gameId]&&live[game.gameId].gameStatusText || game.gameStatusText}
                      <br />
                      {live[game.gameId]&&live[game.gameId].lastPlay}
                    </>:""}
                    
                    </Card.Title>
                </Card.Header>
                <Card.Body className="" style={{fontSize:"10px"}} >
                <table style={{width:"100%"}}>
                  <thead>
                    <tr>
                      <th></th>
                      <th class="score-box">3PM</th>
                      <th class="score-box">3PA</th>
                    </tr>
                  </thead>
                  <tbody>
                  {(favorites[game.gameId].teams[game.homeTeam.teamId])?Object.values(game.homeTeam.players).map((player, id) => (
                    <>
                    {(favorites[game.gameId].teams[game.homeTeam.teamId]?.players[player.personId] || ((live[game.gameId]?.first?.teamMade[game.homeTeam.teamId]?.personId == player?.personId)))?
                        <tr style={{backgroundColor: "#242424", padding:"5px", margin:"-5px", borderRadius:"5px"}}>
                          <td style={{padding:"5px"}}>
                            {(favorites[game.gameId].teams[game.homeTeam.teamId]?.players[player.personId])?"⭐ ":""}
                            {player.playerName}
                            {(live[game.gameId] && live[game.gameId].first.made && live[game.gameId].first.made.personId == player?.personId)?
                                <span style={{display:"block"}}>🏆&nbsp;3PM&nbsp;G</span>:
                                <span>{(live[game.gameId]?.first.teamMade && live[game.gameId]?.first.teamMade[player.teamId]?.personId == player.personId)?<span style={{display:"block"}}>🏆&nbsp;3PM&nbsp;T</span>:""}</span>
                              }
                              {(live[game.gameId] && live[game.gameId].first.attempt && live[game.gameId].first.attempt.personId == player.personId)?
                                <span style={{display:"block"}}>✓&nbsp;3PA&nbsp;G</span>:
                                <span>{(live[game.gameId]?.first.teamAttempt && live[game.gameId]?.first.teamAttempt[player.teamId]?.personId == player.personId)?<span style={{display:"block"}}>✓&nbsp;3PA&nbsp;T</span>:""}</span>
                              }
                          </td>
                          {(live && live[game.gameId])?
                              <>
                                <td class="score-box">{(live[game.gameId].players[player.personId])?live[game.gameId].players[player.personId].live.threesMade:"0"}</td>                                
                                <td class="score-box">{(live[game.gameId].players[player.personId])?live[game.gameId].players[player.personId].live.threesAttempted:"0"}</td>
                              </>:
                            <></>}
                          </tr>
                      :""}
                      </>
                  )):""}
                  {(favorites[game.gameId].teams[game.awayTeam.teamId])?Object.values(game.awayTeam.players).map((player, id) => (
                    <>
                    {(favorites[game.gameId].teams[game.awayTeam.teamId]?.players[player.personId] || ((live[game.gameId]?.first?.teamMade[game.awayTeam.teamId]?.personId == player?.personId)))?
                        <tr style={{backgroundColor: "#242424", padding:"5px", margin:"-5px", borderRadius:"5px"}}>
                          <td style={{padding:"5px"}}>
                            {(favorites[game.gameId].teams[game.awayTeam.teamId]?.players[player.personId])?"⭐ ":""}
                            {player.playerName}
                            {(live[game.gameId] && live[game.gameId].first.made && live[game.gameId].first.made.personId == player?.personId)?
                                <span style={{display:"block"}}>🏆&nbsp;3PM&nbsp;G</span>:
                                <span>{(live[game.gameId]?.first.teamMade && live[game.gameId]?.first.teamMade[player.teamId]?.personId == player.personId)?<span style={{display:"block"}}>🏆&nbsp;3PM&nbsp;T</span>:""}</span>
                              }
                              {(live[game.gameId] && live[game.gameId].first.attempt && live[game.gameId].first.attempt.personId == player.personId)?
                                <span style={{display:"block"}}>✓&nbsp;3PA&nbsp;G</span>:
                                <span>{(live[game.gameId]?.first.teamAttempt && live[game.gameId]?.first.teamAttempt[player.teamId]?.personId == player.personId)?<span style={{display:"block"}}>✓&nbsp;3PA&nbsp;T</span>:""}</span>
                              }
                          </td>
                          {(live && live[game.gameId])?
                              <>
                                <td class="score-box">{(live[game.gameId].players[player.personId])?live[game.gameId].players[player.personId].live.threesMade:"0"}</td>                                
                                <td class="score-box">{(live[game.gameId].players[player.personId])?live[game.gameId].players[player.personId].live.threesAttempted:"0"}</td>
                              </>:
                            <></>}
                          </tr>
                      :""}
                      </>
                  )):""}
                  </tbody>
                  </table>
                </Card.Body>
              </Card> 
            </Col>:"")
            )):"No Favorites"}
            </Row>
            <div class="title-header">
            Games
          </div>
        </Container>
      :""}
      
      <Container fluid style={{padding: "0"}}>
        <Row>
          <Col md="12">
            {(games && games.length >0)?games.map((game, id) => (
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4" style={{textTransform:"uppercase",backgroundColor: "#242424", padding:"10px", margin:"-8px", borderRadius:"5px"}}>
                    <Row>
                      <Col xs="8">
                        <div>
                          <img src={require(`assets/img/logos/${(game.awayTeam.teamTricode)}.png`)} style={{marginTop: "-6px", width:'30px', height:'30px', borderRadius:'10px'}} />&nbsp;
                          {game.awayTeam.teamName}
                          <span class="score-box" style={{fontSize:"18px", borderRadius: "5px", width: "50px", float:"right"}}>{(live[game.gameId]?.awayTeamScore)?live[game.gameId]?.awayTeamScore:""}</span>
                        </div>
                        <div style={{marginTop: "6px", borderTop: "1px solid #717171", paddingTop: "3px"}}>
                          <img src={require(`assets/img/logos/${(game.homeTeam.teamTricode)}.png`)} style={{width:"30px", height:"30px", borderRadius:"10px"}}/>&nbsp;
                          {game.homeTeam.teamName}
                          <span class="score-box" style={{fontSize:"18px", borderRadius: "5px", width: "50px", float:"right"}}>{(live[game.gameId]?.homeTeamScore)?+live[game.gameId]?.homeTeamScore:""}</span>
                        </div>
                      </Col>
                      <Col style={{fontSize:"18px"}}>
                        {live[game.gameId]&&live[game.gameId].gameStatusText || game.gameStatusText}
                        <br /><small>{new Date(game.gameEt).getMonth()+1}/{new Date(game.gameEt).getDate()} {formatAMPM(new Date(game.gameTimeUTC))}</small>
                      </Col>
                    </Row>
                    </Card.Title>
                </Card.Header>
                    <Card.Body className="table-half-width table-responsive px-0">
                      <Table className="" style={{fontSize:"xx-small", overflowX: "hidden"}}>
                        <thead>
                          <tr className="small-header">
                            <th className="border-0">PLYR<hr/>LINEUP STATUS<br />ADD FAV</th>
                            <th className="border-0">LIVE<br />STATS</th>
                            <th className="border-0">THREESDAY<br />RANK<br />SCORE</th>
                            <th className="border-0">3PM/A GAME<hr />AVG sec</th>
                            <th className="border-0">3PM/A TEAM<hr />AVG sec</th>
                            <th className="border-0">AVG 3PPG<hr />GMS PLYD<hr />STARTS</th>
                            {/*<th className="border-0">PLYR 1st<br />3PM/A<br />AVG sec</th>*/}
                            <th className="border-0">TOT<br />3PM/A<hr />3PM %</th>
                          </tr>
                        </thead>
                        <tbody style={{overflowX: "hidden"}}>
                        <tr style={{backgroundRepeat: "repeat-x", backgroundPosition:"0px -20px", backgroundSize:"20%", backgroundImage: "url("+require(`assets/img/logos/${(game.awayTeam.teamTricode)}.png`)+")"}}><td colSpan="1000"  className="team-spacer">{game.awayTeam.teamName}</td></tr>
                        
                          {Object.values(game.awayTeam.players).map((player, id) => (
                            (player.position)?
                            <tr key={id+player.personId} className={(picks[player.personId] && clock.getHours()>=picksGoLiveHour)?"highlight":""}>
                                <td class="text-white">
                                  <b>
                                    {player.playerName}
                                    <br />
                                    ({player.position}) {(player.lineupStatus == "Confirmed")?<span>✓</span>:<span>⌛</span>}
                                    
                                    <br />
                                    {(!favorites[game.gameId]?.teams[player.teamId] || favorites[game.gameId]?.teams[player.teamId]?.players[player.personId]?.type == 'team')?
                                    <Button class="btn btn-xs" onClick={(e)=>{addFavorite({gameId: game.gameId, type: "team", teamId: player.teamId.toString(), playerId: player.personId.toString()})}}>
                                      {(!favorites[game.gameId]?.teams[player.teamId]?.players[player.personId])?<FaPlusCircle />:<FaMinusCircle />} T
                                    </Button>:""}
                                     &nbsp;
                                    {(false && (!favorites[game.gameId] || favorites[game.gameId]?.teams[player.teamId]?.players[player.personId]?.type == 'game'))?
                                    <Button class="btn btn-xs" onClick={(e)=>{addFavorite({gameId: game.gameId, type: "game", teamId: player.teamId.toString(), playerId: player.personId.toString()})}}>
                                      {(!favorites[game.gameId]?.teams[player.teamId]?.players[player.personId])?<FaPlusCircle />:<FaMinusCircle />} G
                                    </Button>
                                    :""}
                                  </b>
                                </td>
                                {(player.threes)?
                                <>
                                {(live && live[game.gameId])?
                                <td>
                                  {(live[game.gameId].players[player.personId])?live[game.gameId].players[player.personId].live.threesMade:"0"}/
                                  {(live[game.gameId].players[player.personId])?live[game.gameId].players[player.personId].live.threesAttempted:"0"}<br />
                                  {(live[game.gameId].players[player.personId]?.onCourt != "false")?<span style={{display:"block"}}>⎄🏀</span>:""}                                  
                                  {(live[game.gameId].first.made && live[game.gameId].first.made.personId == player?.personId)?
                                    <span style={{display:"block"}}>🏆&nbsp;3PM&nbsp;G</span>:
                                    <span>{(live[game.gameId].first.teamMade && live[game.gameId].first.teamMade[player.teamId]?.personId == player.personId)?<span style={{display:"block"}}>🏆&nbsp;3PM&nbsp;T</span>:""}</span>
                                  }
                                  {(live[game.gameId].first.attempt && live[game.gameId].first.attempt.personId == player.personId)?
                                    <span style={{display:"block"}}>✓&nbsp;3PA&nbsp;G</span>:
                                    <span>{(live[game.gameId].first.teamAttempt && live[game.gameId].first.teamAttempt[player.teamId]?.personId == player.personId)?<span style={{display:"block"}}>✓&nbsp;3PA&nbsp;T</span>:""}</span>
                                  }
                                </td>:
                                <td></td>}
                                <td>
                                  {
                                    (Object.values(player.threes.firstTeamThree).length/player.starts
                                    *
                                    ((player.totalThreeMade/player.totalThreeAttempts*100) || 0)
                                    *
                                    ((1000
                                    -
                                    ((Object.values(player.threes.firstThreeTeamAttempt).length>0)?(Object.values(player.threes.firstThreeTeamAttempt).reduce((a, b) => a + b) / Object.values(player.threes.firstThreeTeamAttempt).length).toFixed(0):"0")
                                    )/1000)).toFixed(2)
                                    
                                  }
                                </td>
                                <td class="text-white">
                                  <b>
                                    {Object.values(player.threes.firstThree).length}/{Object.values(player.threes.firstThreeAttempt).length}<br></br>
                                    {(Object.values(player.threes.firstThree).length>0)?(Object.values(player.threes.firstThree).reduce((a, b) => a + b) / Object.values(player.threes.firstThree).length).toFixed(0):"-"}/
                                    {(Object.values(player.threes.firstThreeAttempt).length>0)?(Object.values(player.threes.firstThreeAttempt).reduce((a, b) => a + b) / Object.values(player.threes.firstThreeAttempt).length).toFixed(0):"-"}
                                  </b>
                                </td>
                                <td class="text-white">
                                  <b>
                                    {Object.values(player.threes.firstTeamThree).length}/{Object.values(player.threes.firstThreeTeamAttempt).length}<br></br>
                                    {(Object.values(player.threes.firstTeamThree).length>0)?(Object.values(player.threes.firstTeamThree).reduce((a, b) => a + b) / Object.values(player.threes.firstTeamThree).length).toFixed(0):"-"}/
                                    {(Object.values(player.threes.firstThreeTeamAttempt).length>0)?(Object.values(player.threes.firstThreeTeamAttempt).reduce((a, b) => a + b) / Object.values(player.threes.firstThreeTeamAttempt).length).toFixed(0):"-"}
                                  </b>
                                </td>
                                <td class="text-white">
                                  {(player.totalThreeMade/Object.values(player.threes.attempts).length).toFixed(2)}<br />
                                  {(Object.values(player.threes.attempts).length)}<br />
                                  {player.starts}
                                </td>
                                {/*
                                <td class="text-white">
                                  {(Object.values(player.threes.made).length>0)?(Object.values(player.threes.made).reduce((a, b) => a + b) / Object.values(player.threes.made).length).toFixed(0):"-"}/
                                  {(Object.values(player.threes.attempts).length>0)?(Object.values(player.threes.attempts).reduce((a, b) => a + b) / Object.values(player.threes.attempts).length).toFixed(0):"-"}
                                </td>*/}
                                <td class="text-white">{player.totalThreeMade}/{player.totalThreeAttempts}<br/>{((player.totalThreeMade/player.totalThreeAttempts*100) || 0).toFixed(2)}%</td>
                                </>:<td colSpan="1000">No Data</td>}
                            </tr>:<></>
                            ))}
                            {Object.values(game.awayTeam.players).map((player, id) => (
                            (!player.position && player.threes && player.rosterStatus == "Active")?
                            <tr key={id+player.personId}>
                                <td class="text-white">{player.playerName}</td>
                                {(true)?
                                <>
                                {(live && live[game.gameId])?
                                <td>
                                  {(live[game.gameId].players[player.personId])?live[game.gameId].players[player.personId].live.threesMade:"0"}/
                                  {(live[game.gameId].players[player.personId])?live[game.gameId].players[player.personId].live.threesAttempted:"0"}<br />
                                  {(live[game.gameId].players[player.personId]?.onCourt == "true")?<span style={{display:"block"}}>⎄🏀</span>:""}                                  
                                  {(live[game.gameId].first.made && live[game.gameId].first.made.personId == player?.personId)?
                                    <span style={{display:"block"}}>🏆&nbsp;3PM&nbsp;G</span>:
                                    <span>{(live[game.gameId].first.teamMade && live[game.gameId].first.teamMade[player.teamId]?.personId == player.personId)?<span style={{display:"block"}}>🏆&nbsp;3PM&nbsp;T</span>:""}</span>
                                  }
                                  {(live[game.gameId].first.attempt && live[game.gameId].first.attempt.personId == player.personId)?
                                    <span style={{display:"block"}}>✓&nbsp;3PA&nbsp;G</span>:
                                    <span>{(live[game.gameId].first.teamAttempt && live[game.gameId].first.teamAttempt[player.teamId]?.personId == player.personId)?<span style={{display:"block"}}>✓&nbsp;3PA&nbsp;T</span>:""}</span>
                                  }
                                </td>:
                                <td></td>}
                                <td>
                                  {(Object.values(player.threes.firstTeamThree).length/player.starts*((player.totalThreeMade/player.totalThreeAttempts*100) || 0)).toFixed(2)}
                                </td>
                                <td class="text-white">
                                  <b>
                                    {Object.values(player.threes.firstThree).length}/{Object.values(player.threes.firstThreeAttempt).length}<br></br>
                                    {(Object.values(player.threes.firstThree).length>0)?(Object.values(player.threes.firstThree).reduce((a, b) => a + b) / Object.values(player.threes.firstThree).length).toFixed(0):"-"}/
                                    {(Object.values(player.threes.firstThreeAttempt).length>0)?(Object.values(player.threes.firstThreeAttempt).reduce((a, b) => a + b) / Object.values(player.threes.firstThreeAttempt).length).toFixed(0):"-"}
                                  </b>
                                </td>
                                <td class="text-white">
                                  <b>
                                    {Object.values(player.threes.firstTeamThree).length}/{Object.values(player.threes.firstThreeTeamAttempt).length}<br></br>
                                    {(Object.values(player.threes.firstTeamThree).length>0)?(Object.values(player.threes.firstTeamThree).reduce((a, b) => a + b) / Object.values(player.threes.firstTeamThree).length).toFixed(0):"-"}/
                                    {(Object.values(player.threes.firstThreeTeamAttempt).length>0)?(Object.values(player.threes.firstThreeTeamAttempt).reduce((a, b) => a + b) / Object.values(player.threes.firstThreeTeamAttempt).length).toFixed(0):"-"}
                                  </b>
                                </td>
                                <td class="text-white">
                                  {(player.totalThreeMade/Object.values(player.threes.attempts).length).toFixed(2)}<br />
                                  {(Object.values(player.threes.attempts).length)}<br />
                                  {player.starts}
                                </td>
                                {/*
                                <td>
                                  {(Object.values(player.threes.made).length>0)?(Object.values(player.threes.made).reduce((a, b) => a + b) / Object.values(player.threes.made).length).toFixed(0):"-"}/
                                  {(Object.values(player.threes.attempts).length>0)?(Object.values(player.threes.attempts).reduce((a, b) => a + b) / Object.values(player.threes.attempts).length).toFixed(0):"-"}
                                </td>*/}
                                <td class="text-white">{player.totalThreeMade}/{player.totalThreeAttempts}<br/>{((player.totalThreeMade/player.totalThreeAttempts*100) || 0).toFixed(2)}%</td>
                                </>:<td colSpan="1000">No Data</td>}
                            </tr>:<></>
                            ))}
                        <tr style={{backgroundRepeat: "repeat-x", backgroundPosition:"0px -20px", backgroundSize:"20%", backgroundImage: "url("+require(`assets/img/logos/${(game.homeTeam.teamTricode)}.png`)+")"}}><td colSpan="1000" className="team-spacer">{game.homeTeam.teamName}</td></tr>
                            {Object.values(game.homeTeam.players).map((player, id) => (
                              (player.position)?
                              <tr key={id+player.personId}  className={(picks[player.personId] && clock.getHours()>=picksGoLiveHour)?"highlight":""}>
                                  <td class="text-white">
                                    <b>
                                      {player.playerName}
                                      <br />({player.position}) {(player.lineupStatus == "Confirmed")?<span>✓</span>:<span>⌛</span>}
                                      <br />
                                      {(!favorites[game.gameId]?.teams[player.teamId] || favorites[game.gameId]?.teams[player.teamId]?.players[player.personId]?.type == 'team')?
                                      <Button class="btn btn-xs" onClick={(e)=>{addFavorite({gameId: game.gameId, type: "team", teamId: player.teamId.toString(), playerId: player.personId.toString()})}}>
                                        {(!favorites[game.gameId]?.teams[player.teamId]?.players[player.personId])?<FaPlusCircle />:<FaMinusCircle />} T
                                      </Button>:""}
                                      &nbsp;
                                      {(false && (!favorites[game.gameId] || favorites[game.gameId]?.teams[player.teamId]?.players[player.personId]?.type == 'game'))?
                                      <Button class="btn btn-xs" onClick={(e)=>{addFavorite({gameId: game.gameId, type: "game", teamId: player.teamId.toString(), playerId: player.personId.toString()})}}>
                                        {(!favorites[game.gameId]?.teams[player.teamId]?.players[player.personId])?<FaPlusCircle />:<FaMinusCircle />} G
                                      </Button>
                                      :""}
                                    </b>
                                  </td>
                                  {(player.threes)?
                                  <>
                                  {(live && live[game.gameId])?
                                  <td>
                                    {(live[game.gameId].players[player.personId])?live[game.gameId].players[player.personId].live.threesMade:"0"}/
                                    {(live[game.gameId].players[player.personId])?live[game.gameId].players[player.personId].live.threesAttempted:"0"}<br />
                                    {(live[game.gameId].players[player.personId]?.onCourt != "false")?<span style={{display:"block"}}>⎄🏀</span>:""}                                  
                                    {(live[game.gameId].first.made && live[game.gameId].first.made.personId == player?.personId)?
                                      <span style={{display:"block"}}>🏆&nbsp;3PM&nbsp;G</span>:
                                      <span>{(live[game.gameId].first.teamMade && live[game.gameId].first.teamMade[player.teamId]?.personId == player.personId)?<span style={{display:"block"}}>🏆&nbsp;3PM&nbsp;T</span>:""}</span>
                                    }
                                    {(live[game.gameId].first.attempt && live[game.gameId].first.attempt.personId == player.personId)?
                                      <span style={{display:"block"}}>✓&nbsp;3PA&nbsp;G</span>:
                                      <span>{(live[game.gameId].first.teamAttempt && live[game.gameId].first.teamAttempt[player.teamId]?.personId == player.personId)?<span style={{display:"block"}}>✓&nbsp;3PA&nbsp;T</span>:""}</span>
                                    }
                                  </td>:
                                  <td></td>}
                                  <td>
                                  {
                                    (Object.values(player.threes.firstTeamThree).length/player.starts
                                    *
                                    ((player.totalThreeMade/player.totalThreeAttempts*100) || 0)
                                    *
                                    ((1000
                                    -
                                    ((Object.values(player.threes.firstThreeTeamAttempt).length>0)?(Object.values(player.threes.firstThreeTeamAttempt).reduce((a, b) => a + b) / Object.values(player.threes.firstThreeTeamAttempt).length).toFixed(0):"0")
                                    )/1000)).toFixed(2)
                                  }
                                </td>
                                  <td class="text-white">
                                    <b>
                                      {Object.values(player.threes.firstThree).length}/{Object.values(player.threes.firstThreeAttempt).length}<br></br>
                                      {(Object.values(player.threes.firstThree).length>0)?(Object.values(player.threes.firstThree).reduce((a, b) => a + b) / Object.values(player.threes.firstThree).length).toFixed(0):"-"}/
                                      {(Object.values(player.threes.firstThreeAttempt).length>0)?(Object.values(player.threes.firstThreeAttempt).reduce((a, b) => a + b) / Object.values(player.threes.firstThreeAttempt).length).toFixed(0):"-"}
                                    </b>
                                  </td>
                                  <td class="text-white">
                                    <b>
                                      {Object.values(player.threes.firstTeamThree).length}/{Object.values(player.threes.firstThreeTeamAttempt).length}<br></br>
                                      {(Object.values(player.threes.firstTeamThree).length>0)?(Object.values(player.threes.firstTeamThree).reduce((a, b) => a + b) / Object.values(player.threes.firstTeamThree).length).toFixed(0):"-"}/
                                      {(Object.values(player.threes.firstThreeTeamAttempt).length>0)?(Object.values(player.threes.firstThreeTeamAttempt).reduce((a, b) => a + b) / Object.values(player.threes.firstThreeTeamAttempt).length).toFixed(0):"-"}
                                    </b>
                                  </td>
                                  <td class="text-white">
                                    {(player.totalThreeMade/Object.values(player.threes.attempts).length).toFixed(2)}<br />
                                    {(Object.values(player.threes.attempts).length)}<br /> 
                                    {player.starts}
                                  </td>
                                  {/*<td  class="text-white">
                                    {(Object.values(player.threes.made).length>0)?(Object.values(player.threes.made).reduce((a, b) => a + b) / Object.values(player.threes.made).length).toFixed(0):"-"}/
                                    {(Object.values(player.threes.attempts).length>0)?(Object.values(player.threes.attempts).reduce((a, b) => a + b) / Object.values(player.threes.attempts).length).toFixed(0):"-"}
                                  </td>*/}
                                  <td class="text-white">{player.totalThreeMade}/{player.totalThreeAttempts}<br/>{((player.totalThreeMade/player.totalThreeAttempts*100) || 0).toFixed(2)}%</td>
                                  </>:<td colSpan="1000">No Data</td>}
                              </tr>:<></>
                            ))}
                            {Object.values(game.homeTeam.players).map((player, id) => (
                              (!player.position && player.threes && player.rosterStatus == "Active")?
                              <tr key={id+player.personId}>
                                  <td class="text-white">{player.playerName} {(player.position && player.lineupStatus == "Confirmed")?"("+player.position+")":""}</td>
                                  {(true)?
                                  <>
                                  {(live && live[game.gameId])?
                                <td>
                                  {(live[game.gameId].players[player.personId])?live[game.gameId].players[player.personId].live.threesMade:"0"}/
                                  {(live[game.gameId].players[player.personId])?live[game.gameId].players[player.personId].live.threesAttempted:"0"}<br />
                                  {(live[game.gameId].players[player.personId]?.onCourt == "true")?<span style={{display:"block"}}>⎄🏀</span>:""}                                  
                                  {(live[game.gameId].first.made && live[game.gameId].first.made.personId == player?.personId)?
                                    <span style={{display:"block"}}>🏆&nbsp;3PM&nbsp;G</span>:
                                    <span>{(live[game.gameId].first.teamMade && live[game.gameId].first.teamMade[player.teamId]?.personId == player.personId)?<span style={{display:"block"}}>🏆&nbsp;3PM&nbsp;T</span>:""}</span>
                                  }
                                  {(live[game.gameId].first.attempt && live[game.gameId].first.attempt.personId == player.personId)?
                                    <span style={{display:"block"}}>✓&nbsp;3PA&nbsp;G</span>:
                                    <span>{(live[game.gameId].first.teamAttempt && live[game.gameId].first.teamAttempt[player.teamId]?.personId == player.personId)?<span style={{display:"block"}}>✓&nbsp;3PA&nbsp;T</span>:""}</span>
                                  }
                                </td>:
                                <td></td>}
                                <td>
                                  {(Object.values(player.threes.firstTeamThree).length/player.starts*((player.totalThreeMade/player.totalThreeAttempts*100) || 0)).toFixed(2)}
                                </td>
                                <td class="text-white">
                                  <b>
                                    {Object.values(player.threes.firstThree).length}/{Object.values(player.threes.firstThreeAttempt).length}<br></br>
                                    {(Object.values(player.threes.firstThree).length>0)?(Object.values(player.threes.firstThree).reduce((a, b) => a + b) / Object.values(player.threes.firstThree).length).toFixed(0):"-"}/
                                    {(Object.values(player.threes.firstThreeAttempt).length>0)?(Object.values(player.threes.firstThreeAttempt).reduce((a, b) => a + b) / Object.values(player.threes.firstThreeAttempt).length).toFixed(0):"-"}
                                  </b>
                                </td>
                                <td class="text-white">
                                  <b>
                                    {Object.values(player.threes.firstTeamThree).length}/{Object.values(player.threes.firstThreeTeamAttempt).length}<br></br>
                                    {(Object.values(player.threes.firstTeamThree).length>0)?(Object.values(player.threes.firstTeamThree).reduce((a, b) => a + b) / Object.values(player.threes.firstTeamThree).length).toFixed(0):"-"}/
                                    {(Object.values(player.threes.firstThreeTeamAttempt).length>0)?(Object.values(player.threes.firstThreeTeamAttempt).reduce((a, b) => a + b) / Object.values(player.threes.firstThreeTeamAttempt).length).toFixed(0):"-"}
                                  </b>
                                </td>
                                <td class="text-white">
                                  {(player.totalThreeMade/Object.values(player.threes.attempts).length).toFixed(2)}<br />
                                  {(Object.values(player.threes.attempts).length)}<br />
                                  {player.starts}
                                </td>
                                {/*<td  class="text-white">
                                  {(Object.values(player.threes.made).length>0)?(Object.values(player.threes.made).reduce((a, b) => a + b) / Object.values(player.threes.made).length).toFixed(0):"-"}/
                                  {(Object.values(player.threes.attempts).length>0)?(Object.values(player.threes.attempts).reduce((a, b) => a + b) / Object.values(player.threes.attempts).length).toFixed(0):"-"}
                                </td>*/}
                                <td class="text-white">{player.totalThreeMade}/{player.totalThreeAttempts}<br/>{((player.totalThreeMade/player.totalThreeAttempts*100) || 0).toFixed(2)}%</td>
                                </>:<td colSpan="1000">No Data</td>}
                              </tr>:<></>
                            ))}
                            <tr>
                          </tr>
                        </tbody>
                      </Table>
                    </Card.Body>              
              </Card>
              )):"No Games Today"}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LiveList;
